/**
 * @file   src\pages\DeleteAccount.js
 * @brief  Delete Account page
 * @date   Mar, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useContext } from 'react';
import { AccountContext } from '../contexts/AccountContext';
import EmailForm from '../components/EmailForm';
import OTPForm from '../components/OTPForm';
import Logo from '../assets/img/lionears-logo.svg';

function DeleteAccount() {
  const { otpSent } = useContext(AccountContext);

  return (
    <div className='main-outer d-flex justify-content-center align-items-center'>
      <div className='min-inner'>
        <div className='logo-wrap text-center'>
             <img src={Logo} alt='Lionears Logo' />
        </div>
        <div className='form-wrap'>
          {otpSent ? <OTPForm /> : <EmailForm />}
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;