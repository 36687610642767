/**
 * @file   src\components\OTPForm.js
 * @brief  OTP Form Component
 * @date   Mar, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useContext } from 'react';
import { Button } from "react-bootstrap";
import { Link, useNavigate} from "react-router-dom";
import OtpInput from 'react-otp-input';
import { AccountContext } from '../contexts/AccountContext';

const OTPForm = () => {
  const { otp, setOtp, setOtpSent, error, setError, email, setSuccessMessage } = useContext(AccountContext);
  const navigate = useNavigate();

  const handleVerify = async () => {
    try {
      // API call to verify OTP
      const verifyResponse = await fetch('https://app.lionears.com/api/v2/verifyOTP/verify-delaccotp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'api_key':'$2a$12$uQVremnsQ7odlre2346bmI2xLUeYqLzElgQYQ5DmGSmBbq9XM' },
        body: JSON.stringify({ email, otp }),
      });

      const verifyData = await verifyResponse.json();  // Parse the response body to JSON

      if (!verifyResponse.ok) {
        throw new Error('OTP verification failed.');
      }

      if (verifyData.responseCode === 400) {
        throw new Error('Invalid OTP or OTP has been expired.');
      }

      console.log("OTP verified successfully", verifyData);
      
      // API call to delete account, proceed only if OTP verification was successful
      const deleteResponse = await fetch('https://app.lionears.com/api/v1/deleteAccount/delete-account-fromweb', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'api_key':'$2a$12$uQVremnsQ7odlre2346bmI2xLUeYqLzElgQYQ5DmGSmBbq9XM' },
        body: JSON.stringify({ email }),
      });

      const deleteData = await deleteResponse.json();
      if (!deleteResponse.ok || (deleteData.responseCode && deleteData.responseCode !== 200)) {
        throw new Error('Account deletion failed.');
      } else {
        setError('Account has been deleted successfully.');
        setSuccessMessage('Account has been deleted successfully.');
             // Clear OTP value
        setOtp('');
        setOtpSent(false);
        console.log("Account deleted successfully", deleteData);
        navigate('/');
      }
    } catch (error) {
      console.error("Error processing your request:", error);
      setError(error.message);
    }
  };

  return (
    <>
      <h1 className='text-center'>DELETE ACCOUNT</h1>
      <p className='text-center m-auto'>Please enter the OTP sent to your registered email address. Please note that once OTP is verified, account will be deleted for ever.</p>
      {error && <div className="alert alert-danger" role="alert">{error}</div>} 
      <OtpInput
        containerStyle='otp-outer'
        inputStyle='otp-input'
        value={otp}
        onChange={setOtp}
        numInputs={4}
        shouldAutoFocus={true}
        renderInput={(props) => <input {...props} />}
      />
      <Button variant='primary' className='w-100 mt-3' onClick={handleVerify}>Verify OTP</Button>
      <Link to='/' className='d-block m-auto'  onClick={() => setOtpSent(false)}>Back to Home</Link>
     
    </>
  );
};

export default OTPForm;