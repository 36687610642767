/**
 * @file   src\contexts\AccountContext.js
 * @brief  Account Context : context handler
 * @date   Mar, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { createContext, useState } from 'react';
export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);  // State to indicate OTP has been sent
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const contextValue = {
    email,
    setEmail,
    otp,
    setOtp,
    otpSent,
    setOtpSent,
    error,
    setError,
    successMessage,
    setSuccessMessage,
  };

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};


