/**
 * @file   src\App.js
 * @brief  Delete Account page
 * @date   Mar, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import DeleteAccount from './pages/DeleteAccount';
import NotFound from './pages/NotFound';
import { AccountProvider } from './contexts/AccountContext';

function App() {
  return (
    <BrowserRouter>
      <AccountProvider>
        <Routes>
          <Route path="/" element={<DeleteAccount />} />
          <Route path="/verify-otp"  element={<DeleteAccount />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AccountProvider>
    </BrowserRouter>
  );
}

export default App;
