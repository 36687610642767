/**
 * @file   src\components\EmailForm.js
 * @brief  Email Form Component
 * @date   Mar, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useContext, useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AccountContext } from '../contexts/AccountContext';
import LSInput from './LSInput';

const EmailForm = () => {
  const { setEmail, setError: setContextError, setOtpSent, successMessage, setSuccessMessage } = useContext(AccountContext);
  const [localEmail, setLocalEmail] = useState('');
  const [localError, setLocalError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail(localEmail);

    if (!validateEmail(localEmail)) {
      setLocalError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch('https://app.lionears.com/api/v2/verifyOTP/send-email-otp-for-account-deletion', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'api_key':'$2a$12$uQVremnsQ7odlre2346bmI2xLUeYqLzElgQYQ5DmGSmBbq9XM' },
        body: JSON.stringify({ email: localEmail }),
      });

      const data = await response.json(); // Parse JSON from the response

      if (!response.ok || (data.responseCode && data.responseCode !== 200)) {
        //throw new Error(data.error.message || 'Failed to send OTP.');
        throw new Error('Failed to send One Time Passcode.');
      }

      setOtpSent(true);
      navigate('/verify-otp');  // Navigate to OTP verification only if no error
      setLocalError('');
      setContextError('');
    } catch (error) {
      setLocalError(error.message);
      setContextError(error.message);
    }
  };

  useEffect(() => {
    if (successMessage) {
      setLocalError(successMessage);
      setSuccessMessage('');
    }
  }, [successMessage, setSuccessMessage]);

  return (
    <>
      <h1 className='text-center'>DELETE ACCOUNT</h1>
      <p className='text-center m-auto'>Please enter your registered email to delete account.</p>
      {localError && <div className="alert alert-danger" role="alert">{localError}</div>} 
      <LSInput
        type='email'
        placeholder='Email Address'
        value={localEmail}
        onChange={(e) => setLocalEmail(e.target.value)}
      />
      <Button variant='primary' className='w-100 mt-3' onClick={handleSubmit}>Submit</Button>
      
      
      
    </>
  );
};

export default EmailForm;

