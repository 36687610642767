/**
 * @file   src\components\LSInput.js
 * @brief  Reusable Input Component
 * @date   Mar, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Form } from 'react-bootstrap';
import './LSInput.scss'

const LSInput = ({ label, type, placeholder, value, onChange }) => {
  return (
    <Form.Group className='ls-input'>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        required
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
    </Form.Group>
  );
};

export default LSInput;


